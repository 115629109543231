<template>
    <div class="col-12">
        <template v-if="depositMethodError">
            <!--Error-->
            <error></error>
            <p class="row justify-content-center">{{depositMethodError}}</p>
        </template>

        <div v-if="showLoader">
            <div class="text-center mt-5">
                <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div><br/>
            </div>
        </div>

        <div class="luqapay-banks banks-list" v-if="luqapayBanks && !showLoader">
            <h4 class="deposit-title">Select bank</h4>
                <div class="bank row" v-for="bank in luqapayBanks.availableBanks" :key="bank.swiftCode">
                    <div class="col-12 col-md-6">
                        <img class="logo" :src="getBankLogo(bank.swiftCode)" />
                        <span class="title">{{bank.bankName}}</span>
                    </div>

                    <div class="col-4 col-md-2" v-for="amount in bank.availableAmounts.slice(0,3)" :key="amount.activeAmountId">
                        <button @click="selectAmount(luqapayBanks.paymentRef, amount.amount, amount.currency, amount.activeAmountId, bank.swiftCode)" type="button" class="btn btn-default btn-sm" >
                            <span class="amount">{{amount.amount}}</span>
                        </button>
                    </div>
                </div>    
        </div>

    

    </div>
</template>

<script>
    import {initCommunityBank, getCommunityBankAmounts, payCommunityBank} from "../../../services/backend.service";
    import {getBankLogo} from "../../../helpers/helpers";

    export default {
        name: 'LuqapayCommunityDeposit',
        data() {
            return {
                showLoader:true,
                language: null,
                customerId: null,
                ipAddress: null,
                playerCurrency: null,
                playerCurrencySymbol: null,
                brandUrl: null,
                amount: null,
                minDeposit: 5,
                maxDeposit: 100,
                limitReached: null,
                submitStatus: null,
                processorName: "LUQAPAY",
                processorType: "EWALLET",
                walletType : null,
                showBankSection: false,
                depositMethodError: null,
                luqapayBanks: null,
            }
        },
        components: {
            Error: () => import('../../others/Error'),
        },
        mounted() {
            this.initLuqapayCommunityBank();
        },
        methods: {
            getBankLogo(swiftCode){return getBankLogo(swiftCode);},
            initLuqapayCommunityBank() {
                this.showLoader = true;

                this.language = this.$router.history.current.query.language;
                this.customerId = this.$router.history.current.query.customerId;
                this.ipAddress = this.$router.history.current.query.ipAddress;
                this.playerCurrency = this.$router.history.current.query.playerCurrency;
                this.playerCurrencySymbol = this.$router.history.current.query.playerCurrencySymbol;
                this.amount = parseInt(this.$router.history.current.query.amount);
                this.brandUrl = this.$router.history.current.query.brandUrl;
                this.walletType = this.$router.history.current.query.walletType;
                this.accountInvalid = this.$router.history.current.query.errorCode;

                initCommunityBank(this.playerCurrency, this.amount, this.processorName, this.processorType, this.customerId, this.ipAddress, this.brandUrl).then(res => {
                    this.showLoader = false;
                    console.log(res);
                    if(res.data.success && res.data.data){
                        this.luqapayBanks = res.data.data;
                    } else {
                        this.depositMethodError = res.data.message && res.data.message !== "No message available" ? res.data.message : this.$t('error-2');
                    }
                }).catch( e => {
                    this.depositMethodError = this.$t('error-2');
                    console.log(`%c Error in LuqapayCommunityDeposit.vue - method: initCommunityBank - ${e}`, 'color:red');
                });

            },
            
            selectAmount(paymentRef, amount, currency, activeAmountId, swiftCode){
                this.showLoader = true;
                payCommunityBank(paymentRef, amount, currency, activeAmountId, navigator.userAgent, swiftCode).then(res => {
                    this.showLoader = false;

                    if(res.data.success && res.data.data.redirectUrl){
                        this.luqapayBanks = null;
                        this.depositMethodError = null;
                        window.location.href = res.data.data.redirectUrl;
                    } else {
                        this.depositMethodError = res.data.data.errorCode && res.data.data.errorCode !== "No message available" ? res.data.data.errorCode : this.$t('error-2');
                        this.luqapayBanks = null;
                        this.initLuqapayCommunityBank();
                    }
                    
                }).catch( e => {
                    this.showLoader = false;
                    this.depositMethodError = this.$t('error-2');
                    console.log(`%c Error in LuqapayCommunityDeposit.vue - method: payCommunityBank - ${e}`, 'color:red');
                });
            },
        },
    }
</script>
